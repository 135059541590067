<template>
	<div id="singleBlog" v-if="!this.$store.state.loading == true">
		<nav class="main-breadcrumb" aria-label="breadcrumb" :style="{
			'background-image': `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) ,url(${require('@/assets/images/backgrounds/breadcrumb-2.jpg')})`,
		}">
			<div class="container h-100">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						تفاصيل المقال
					</li>
				</ol>
			</div>
		</nav>
		<!--single property-->
		<section class="single-property-page my-5" v-if="single_blog">
			<div class="container">
				<div class="property-content">
					<div class="row">
						<div class="col-md-9">
							<!--images-->
							<div class="single-property-box">
								<div class="single-property-images">
									<div class="slider-for">
										<div class="item overflow-hidden" data-aos="fade-down" data-aos-duration="1500">
											<!-- عرض الصورة يبقى ضعف الطول، عشان الصورة تظهر حلوة بس مش أكتر-->
											<a data-fancybox="demo" :src="single_blog.main_image" data-caption="">
												<img class="img-fluid" :src="single_blog.main_image"
													:alt="single_blog.title" />
											</a>
										</div>
									</div>
								</div>

								<!--details-->
								<div class="single-property-text mt-4 overflow-hidden">
									<div class="mb-4" data-aos="fade-right" data-aos-duration="1500">
										<h3>{{ single_blog.title }}</h3>
									</div>
									<p v-html="single_blog.description" data-aos="fade-up" data-aos-duration="1500"></p>
								</div>

								<!--video-->
								<div class="single-property-video overflow-hidden">
									<div class="mb-4" data-aos="fade-right" data-aos-duration="1500">
										<h2 class="section-title">فيديو</h2>
									</div>
									<div class="video">
										<div class="ratio ratio-16x9" data-aos="fade-up" data-aos-duration="1500">
											<iframe :src="single_blog.video" title="YouTube video" allowfullscreen></iframe>
										</div>
										<!-- في حالة لو الفيديو مرفوع سيرفر مش يوتيوب-->
									</div>
								</div>
							</div>
						</div>
						<!--aside area-->
						<div class="col-md-3 overflow-hidden">
							<aside>
								<div class="share-property mb-5 overflow-hidden">
									<div class="subtitle mb-4">
										<h3 class="section-title fs-3" data-aos="fade-down" data-aos-duration="1500">مشاركة
											الآن</h3>
									</div>
									<ul class="d-flex justify-content-center align-items-center gap-3" data-aos="fade-right"
										data-aos-duration="1500">
										<li>
											<ShareNetwork network="facebook" :url="url" :title="single_blog.title"
												:description="single_blog.description"
												quote="The hot reload is so fast it\'s near instant. - Evan You"
												hashtags="vuejs,vite">
												<i class="bi bi-facebook"> </i>
											</ShareNetwork>
										</li>
										<li>
											<ShareNetwork network="twitter" :url="url" :title="single_blog.title"
												hashtags="vuejs,vite">
												<i class="bi bi-twitter"> </i>
											</ShareNetwork>
										</li>
										<li>
											<ShareNetwork network="WhatsApp" :url="url" :title="single_blog.title"
												:description="single_blog.description">
												<i class="bi bi-whatsapp"></i>
											</ShareNetwork>
										</li>
									</ul>
								</div>

								<div class="related-properties">
									<div class="subtitle mb-4">
										<h3 class="section-title fs-3">مواضيع ذات صلة</h3>
									</div>
									<div class="row" v-if="single_blog.related">
										<div class="col-sm-12 col-md-12" v-for="related in single_blog.related"
											:key="related.id" data-aos="fade-up" data-aos-offset="200"
											data-aos-duration="1500">
											<div class="card mb-4">
												<div class="imgBox w-100">
													<router-link :to="`/blog/${related.id}`">
														<img :src="related.image" class="card-img-top h-100 w-100"
															:alt="related.title" />
													</router-link>
												</div>
												<div class="card-body">
													<router-link :to="`/blog/${related.id}`">
														<p class="card-text text-limit">
															{{ related.title }}
														</p>
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--single property-->
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {
	name: 'SingleBlogView',

	data() {
		return {
			single_blog: null,
		};
	},
	methods: {
		async initData() {
			this.$store.state.loading = true;
			await axios
				.get(`singlePropety?id=${this.$route.params.id}`, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then((result) => {
					this.single_blog = result.data.data;
					this.$store.state.loading = false;
				});
		},
	},
	async created() {
		this.initData();
		this.$watch(
			() => this.$route.params,
			async () => {
				this.initData();
			}
		);
	},
};
</script>