import { createRouter, createWebHistory } from 'vue-router';
import AboutView from '../views/AboutView.vue';
import BlogsView from '../views/BlogsView.vue';
import ContactView from '../views/ContactView.vue';
import FilterView from '../views/FilterView.vue';
import HomeView from '../views/HomeView.vue';
import SingleBlogView from '../views/SingleBlogView.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomeView,
	},
	{
		path: '/about',
		name: 'About',
		component: AboutView,
	},
	{
		path: '/contact',
		name: 'Contact',
		component: ContactView,
	},
	{
		path: '/blogs',
		name: 'Blogs',
		component: BlogsView,
	},
	{
		path: '/filter',
		name: 'Filter',
		component: FilterView,
	},
	{
		path: '/blog/:id',
		name: 'Blog',
		component: SingleBlogView,
		params: true,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return window.scrollTo({
				top: document.querySelector(to.hash).offsetTop,
				behavior: 'smooth',
			});
		}
		return window.scrollTo({ top: 0, behavior: 'smooth' });
	},
});

export default router;
