<template>
	<div id="blogsPage" v-if="!this.$store.state.loading == true">
		<nav class="main-breadcrumb" aria-label="breadcrumb" :style="{
			'background-image': `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) ,url(${require('@/assets/images/backgrounds/breadcrumb-2.jpg')})`,
		}">
			<div class="container h-100">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
					</li>
					<li class="breadcrumb-item active" aria-current="page">المقالات</li>
				</ol>
			</div>
		</nav>
		<section class="properties properties-page">
			<div class="container">
				<div class="property-content">
					<form @submit="onSubmitSearchForm" class="my-5">
						<div class="row">
							<div class="col-md-5 col-sm-12">
								<div class="input-area">
									<input class="form-control" v-model="formDataFields.text" placeholder="البحث"
										id="search_text" type="text" />
								</div>
							</div>
							<div class="col-md-2 col-sm-12">
								<div class="buttons-area">
									<button class="btn submit blog-filter" type="submit">
										ابحث
										<i class="bi bi-search"></i>
									</button>
								</div>
							</div>
						</div>
					</form>
					<br />
					<br />
					<br />
					<div class="row">
						<div class="col-md-3">
							<div class="filter-properties py-4">
								<div class="type" v-if="blogsData.categories">
									<div class="subtitle">
										<h3>اقسام المدوانات</h3>
									</div>
									<div class="form-check pb-2" v-for="category in blogsData.categories"
										:key="category.id">
										<input @change="onSubmitForm" class="form-check-input"
											:id="'propertyTypeRadio' + category.id" v-model="formDataFields.category_id"
											:value="category.id" type="radio" name="propertyTypeRadio" />
										<label class="form-check-label" :for="'propertyTypeRadio' + category.id">
											{{ category.title }}</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-9 blogsCards" v-if="filterVal.length">
							<div class="row">
								<div class="col-md-4" v-for="property in filterVal" :key="property.id" data-aos="fade-left"
									data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="500">
									<div class="card mb-4">
										<div class="imgBox w-100">
											<router-link :to="`/blog/${property.id}`">
												<img :src="property.image" class="card-img-top h-100 w-100"
													:alt="property.title" />
											</router-link>
										</div>
										<div class="card-body">
											<router-link :to="`/blog/${property.id}`">
												<p class="card-text text-limit">
													{{ property.title }}
												</p>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-9 blogsCards" v-else>
							<div class="row">
								<div class="col-md-4" data-aos="fade-left" data-aos-anchor="#example-anchor"
									:data-aos-offset="property.id * 100" :data-aos-duration="property.id * 50"
									v-for="property in blogsData.data" :key="property.id">
									<div class="card mb-4">
										<div class="imgBox w-100">
											<router-link :to="`/blog/${property.id}`">
												<img :src="property.image" class="card-img-top h-100 w-100"
													:alt="property.title" />
											</router-link>
										</div>
										<div class="card-body">
											<router-link :to="`/blog/${property.id}`">
												<p class="card-text text-limit">
													{{ property.title }}
												</p>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';

export default {
	name: 'BlogsView',
	data: function () {
		return {
			formDataFields: {
				category_id: null,
			},
			filterVal: [],
		};
	},
	methods: {
		...mapActions(['filterFormData']),
		onSubmitSearchForm(e) {
			e.preventDefault();

			this.filterFormData(this.formDataFields).then(() => {
				if (this.$store.state.searchParams) {
					e.target.reset();
					this.formDataFields.text = null;
				}
			});
		},

		onSubmitForm(e) {
			e.preventDefault();
			this.filterVal = this.blogsData.data.filter(
				(prop) => prop.category_id == e.target.value
			);
		},
	},

	computed: {
		...mapState(['blogsData']),
	},
	created() {
		this.$store.dispatch('getBlogData');
	},
};
</script>