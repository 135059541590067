<template>
	<div id="contactPage" v-if="!this.$store.state.loading == true">
		<nav class="main-breadcrumb" aria-label="breadcrumb" :style="{
			'background-image': `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) ,url(${require('@/assets/images/backgrounds/breadcrumb-2.jpg')})`,
		}">
			<div class="container h-100">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						تواصل معنا
					</li>
				</ol>
			</div>
		</nav>

		<div class="map" data-aos="fade-down" data-aos-duration="1500">
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110550.39964813001!2d31.11960275764731!3d30.01676975648693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458469235579697%3A0x4e91d61f9878fc52!2sGiza%2C%20El%20Omraniya%2C%20Giza%20Governorate!5e0!3m2!1sen!2seg!4v1630871023803!5m2!1sen!2seg"
				width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
		</div>
		<!-- contact page map-->
		<!-- contact-->
		<section class="contact overflow-hidden" v-if="contactData">
			<div class="container">
				<div class="info-area pb-5">
					<div class="row my-5">
						<div class="col-md-4 col-sm-12" data-aos="fade-right" data-aos-duration="1500">
							<div class="info-box">
								<i class="bi bi-envelope"> </i>
								<h4>الإيميل</h4>
								<a :href="`mailto:${contactData.data.email}`">{{
									contactData.data.email
								}}</a>
							</div>
						</div>
						<div class="col-md-4 col-sm-12" data-aos="fade-right" data-aos-duration="2000">
							<div class="info-box">
								<i class="bi bi-telephone"></i>
								<h4>الهاتف</h4>
								<a :href="`tel:${contactData.data.phone}`">
									{{ contactData.data.phone }}
								</a>
							</div>
						</div>
						<div class="col-md-4 col-sm-12" data-aos="fade-right" data-aos-duration="2500">
							<div class="info-box">
								<i class="bi bi-pin-map"></i>
								<h4>الموقع</h4>
								<span> {{ contactData.data.address }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-area my-5">
					<div class="main-title text-center" data-aos="fade-up" data-aos-duration="1500">
						<h2 class="section-title">تواصل معنا الان</h2>
					</div>
					<div class="contact-box">
						<!-- Form Container -->
						<div class="form-content pt-5" data-aos="fade-right" data-aos-duration="2000">
							<form class="" action="" @submit.prevent="handleContactForm">
								<div class="row p-4 g-4 justify-content-center">
									<!-- Full Name -->
									<div class="col-md-11 form-group">
										<input name="name" type="text" class="form-control" placeholder="الاسم كاملاً"
											aria-label="الاسم كاملاً" v-model="contactFormData.name"
											v-on:keyup="nameMonitor" />
										<div class="invalid-feedback d-block" v-if="contactErrors.name">
											{{ contactErrors.name }}
										</div>
									</div>

									<!-- Email -->
									<div class="col-md-11 form-group">
										<input name="email" type="email" class="form-control"
											placeholder="البريد الإلكتروني" aria-label="البريد الإلكتروني"
											v-model="contactFormData.email" v-on:keyup="emailMonitor" />
										<div class="invalid-feedback d-block" v-if="contactErrors.email">
											{{ contactErrors.email }}
										</div>
									</div>

									<!-- Phone Number -->
									<div class="col-md-11 form-group">
										<input name="phone" type="number" class="form-control" placeholder="رقم الجوال"
											aria-label="رقم الجوال" v-model="contactFormData.phone"
											v-on:keyup="phoneMonitor" />
										<div class="invalid-feedback d-block" v-if="contactErrors.phone">
											{{ contactErrors.phone }}
										</div>
									</div>

									<!-- Message -->
									<div class="col-md-11 form-group">
										<textarea name="message" rows="6" class="form-control"
											placeholder="اكتب رسالتك هنا ..." aria-label="اكتب رسالتك هنا ..."
											v-model="contactFormData.message" v-on:keyup="messageMonitor"></textarea>
										<div class="invalid-feedback d-block" v-if="contactErrors.message">
											{{ contactErrors.message }}
										</div>
									</div>

									<!-- Submit Button -->
									<div class="col-md-11 d-flex justify-content-center align-items-center">
										<button class="btn " type="submit">
											<i class="bi bi-send"></i>
											<span class="ms-2">إرسال الرسالة</span>
										</button>
									</div>

									<!-- Sent Successfully Feedback Message -->
									<div class="valid-feedback" v-if="sent">
										تم إرسال الرسالة بنجاح
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
// @ is an alias to /src
// import MyBreadcrumb from '@/components/BreadcrumbComponent.vue';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ContactView',
	data() {
		return {
			sent: false,
			contactFormData: {
				name: null,
				phone: null,
				email: null,
				message: null,
			},
			contactErrors: {
				name: null,
				email: null,
				phone: null,
				message: null,
			},
		};
	},
	components: {
		// MyBreadcrumb,
	},
	methods: {
		...mapActions(['storeFormData']),
		handleContactForm(e) {
			this.sending = true;
			this.sent = null;
			this.storeFormData(this.contactFormData).then(() => {
				if (!this.$store.state.contactErrorsApi) {
					e.target.reset();
					this.sent = true;
					this.contactFormData.name = null;
					this.contactFormData.email = null;
					this.contactFormData.phone = null;
					this.contactFormData.message = null;
					this.contactErrors.name = null;
					this.contactErrors.email = null;
					this.contactErrors.phone = null;
					this.contactErrors.message = null;
				} else {
					this.sent = null;
					let errors = this.$store.state.contactErrorsApi.data.data;
					this.contactErrors.name = errors.name[0];
					this.contactErrors.email = errors.email[0];
					this.contactErrors.phone = errors.phone[0];
					this.contactErrors.message = errors.message[0];
				}
			});
		},
	},
	computed: {
		...mapState(['contactData']),
	},
	created() {
		this.$store.dispatch('getContactData');
	},
};
</script>
