import axios from 'axios';
import { createStore } from 'vuex';
import router from '../router';

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};
axios.defaults.baseURL = 'https://demoyoursite.xyz/minesy/mawdoo3/public/api/';

export default createStore({
	state: {
		loading: true,
		settingsData: [],
		aboutData: [],
		blogsData: [],
		homeData: [],
		contactDataData: [],
		contactErrorsApi: [],
		searchParams: [],
		contacthData: [],
	},
	getters: {},
	mutations: {
		setSettingsData: (state, settingsData) =>
			(state.settingsData = settingsData),
		setAboutData: (state, aboutData) => (state.aboutData = aboutData),
		setBlogsData: (state, blogsData) => (state.blogsData = blogsData),
		setHomeData: (state, homeData) => (state.homeData = homeData),
		setContactData: (state, contactData) => (state.contactData = contactData),
		setSearchData: (state, searchParams) => (state.searchParams = searchParams),
		setStoreContacthData: (state, contacthData) =>
			(state.contacthData = contacthData),
	},
	actions: {
		async storeFormData({ commit }, contactFormData) {
			await axios
				.post('contact', contactFormData, {})
				.then((result) => {
					console.log(result);
					commit('setStoreContacthData', result.data.data);
					this.state.contactErrorsApi = null;
				})
				.catch((error) => {
					this.state.contactErrorsApi = error.response;
				});
		},

		async filterFormData({ commit }, formDataFields) {
			await axios
				.get(`filter?text=${formDataFields.text}`)

				.then((response) => {
					console.log(response.data.data);

					commit('setSearchData', response.data.data);
					router.push('/filter');
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getSettingsData({ commit }) {
			await axios
				.get('setting', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					commit('setSettingsData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getAboutData({ commit }) {
			this.state.loading = true;

			await axios
				.get('about', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setAboutData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getBlogData({ commit }) {
			this.state.loading = true;

			await axios
				.get('properties', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setBlogsData', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getHomeData({ commit }) {
			this.state.loading = true;

			await axios
				.get('home', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setHomeData', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async getContactData({ commit }) {
			this.state.loading = true;

			await axios
				.get('contact', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setContactData', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	modules: {},
});
