<template>
	<div id="filterPage" v-if="!this.$store.state.loading == true">
		<nav class="main-breadcrumb" aria-label="breadcrumb" :style="{
			'background-image': `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) ,url(${require('@/assets/images/backgrounds/breadcrumb-2.jpg')})`,
		}">
			<div class="container h-100">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						نتائج البحث
					</li>
				</ol>
			</div>
		</nav>
		<section class="properties properties-page my-4">
			<div class="container">
				<div class="property-content">
					<div class="row">
						<div class="col-md-8 col-lg-12" v-if="searchParams.length">
							<div class="row">
								<div class="col-md-3" v-for="property in searchParams" :key="property.id">
									<div class="card mb-4">
										<div class="imgBox w-100">
											<router-link :to="`/blog/${property.id}`">
												<img class="card-img-top h-100 w-100" :src="property.image"
													:alt="property.title" />
											</router-link>
										</div>
										<div class="card-body">
											<router-link :to="`/blog/${property.id}`">
												<p class="card-text text-limit">
													{{ property.title }}
												</p>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
	name: 'AboutView',

	computed: {
		...mapState(['searchParams']),
	},
	created() {
		this.$store.dispatch('filterFormData');
	},
};
</script>
