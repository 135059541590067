<template>
	<header v-if="headerData">
		<nav class="navbar navbar-expand-lg bg-white fixed-top">
			<div class="container">
				<!-- Navbar Logo -->
				<router-link class="navbar-brand" :to="{ name: 'Home' }">
					<h3 class="m-0">{{headerData.website_title}}</h3>
				</router-link>

				<!-- Navbar Toggler -->
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#offcanvasNavbar"
					aria-controls="offcanvasNavbar"
				>
					<span class="navbar-toggler-icon"></span>
				</button>

				<!-- Navbar Content -->
				<div
					class="offcanvas offcanvas-end"
					tabindex="-1"
					id="offcanvasNavbar"
					aria-labelledby="offcanvasNavbarLabel"
				>
					<div class="offcanvas-header">
						<router-link
							class="offcanvas-title navbar-brand"
							:to="{ name: 'Home' }"
						>
							<h3 class="m-0">معلومة</h3>
						</router-link>

						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
						></button>
					</div>
					<div class="offcanvas-body">
						<ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
							<li class="nav-item">
								<router-link
									class="nav-link"
									aria-current="page"
									:to="{ name: 'Home' }"
								>
									الرئيسية
								</router-link>
							</li>

							<li class="nav-item">
								<router-link class="nav-link" :to="{ name: 'About' }">
									من نحن
								</router-link>
							</li>

							<li class="nav-item">
								<router-link class="nav-link" :to="{ name: 'Blogs' }">
									المقالات
								</router-link>
							</li>

							<li class="nav-item">
								<router-link class="nav-link" :to="{ name: 'Contact' }">
									تواصل معنا
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
export default {
	props: {
		headerData: Array,
	},
};
</script>
