<template>
	<div id="aboutPage" v-if="!this.$store.state.loading == true">
		<nav class="main-breadcrumb" aria-label="breadcrumb" :style="{
			'background-image': `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) ,url(${require('@/assets/images/backgrounds/breadcrumb-2.jpg')})`,
		}">
			<div class="container h-100">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
					</li>
					<li class="breadcrumb-item active" aria-current="page">من نحن</li>
				</ol>
			</div>
		</nav>
		<section class="about" v-if="aboutData">
			<div class="container">
				<div class="about-content">
					<div class="row">
						<div class="col-md-12 col-lg-12" data-aos="fade-right" data-aos-offset="300"
							data-aos-easing="ease-in-sine">
							<div class="about-box">
								<i :class="aboutData.vision.icon"></i>
								<div class="main-title">
									<h2>
										{{ aboutData.vision.title }}<span class="line"></span>
									</h2>
									<div class="row">
										<div class="col-md-12 col-lg-6 d-flex align-items-center">
											<div class="about-desc" v-html="aboutData.vision.description"></div>
										</div>
										<div class="col-md-12 col-lg-6 imgBox overflow-hidden">
											<img class="about-imgs img-fluid overflow-hidden" :src="aboutData.vision.icon"
												:alt="aboutData.vision.title" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12 col-lg-12" data-aos="fade-left" data-aos-offset="300"
							data-aos-easing="ease-in-sine">
							<div class="about-box">
								<div class="main-title">
									<h2>
										{{ aboutData.mission.title }}<span class="line"></span>
									</h2>
									<div class="row">
										<div class="col-md-12 col-lg-6 imgBox overflow-hidden">
											<img class="about-imgs img-fluid overflow-hidden" :src="aboutData.mission.icon"
												:alt="aboutData.mission.title" />
										</div>
										<div class="col-md-12 col-lg-6 d-flex align-items-center">
											<div class="about-desc" v-html="aboutData.mission.description"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
	name: 'AboutView',
	computed: {
		...mapState(['aboutData']),
	},
	created() {
		this.$store.dispatch('getAboutData');
	},
};
</script>
