<template>
	<main id="home-page" v-if="!this.$store.state.loading == true">
		<!-- Hero Section  -->
		<section class="container-fluid home-banner px-0 d-flex justify-content-center align-items-center">
			<div class="container text-center">
				<div class="row g-4 d-flex flex-column justify-content-center align-items-center">
					<!-- Text Container -->
					<div class="col-12 text-container">
						<h2 class="subtitle display-6">أهلا بك في معلومة</h2>
						<h3 class="title display-3">أكبر موقع عربي بالعالم.</h3>
					</div>

					<!-- Search Bar -->
					<div class="col-12 search-bar">
						<form @submit="onSubmitForm">
							<div class="row g-2">
								<!-- Input Field -->
								<div class="form-floating col-12 col-sm-9">
									<input type="text" v-model="formDataFields.text" class="form-control" id="search_text"
										placeholder="ابحث عن معلومة تريد معرفتها ..." />
								</div>

								<!-- Search Button -->
								<div class="col-12 col-sm-3 d-flex justify-content-center align-items-center">
									<button type="submit"
										class="btn btn-primary d-flex justify-content-center align-items-center">
										<span class="me-2">ابحث الآن</span>
										<i class="bi bi-search"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>

		<!-- Popular Blogs -->
		<section class="blogs" v-if="homeData.data.blogs">
			<div class="container py-5">
				<!-- Section Title -->
				<div class="text-container">
					<h2 class="section-title display-3 text-center" data-aos="flip-down">
						أكثر المقالات قراءة
					</h2>
				</div>

				<!-- Blogs Container -->
				<div class="blog-content pt-5">
					<div class="row g-4">
						<div class="col-md-4" v-for="(blog, index) in homeData.data.blogs" :key="index"
							data-aos="zoom-in-up" :data-aos-delay="index * 50">
							<!-- Blog Card -->
							<router-link :to="`/blog/${blog.id}`">
								<div class="blog-box card d-flex flex-column overflow-hidden">
									<div class="blog-image h-100 overflow-hidden">
										<img :src="blog.image" :alt="blog.title"
											class="card-img-top img-fluid w-100 h-100" />
									</div>
									<div class="blog-details card-body py-3 px-2">
										<h5 class="title card-title text-limit" style="--lines: 2">
											{{ blog.title }}
										</h5>
									</div>
								</div>
							</router-link>
						</div>

						<!-- View More -->
						<div class="col-12 pt-4 d-flex justify-content-center align-items-center">
							<router-link :to="{ name: 'Blogs' }">
								<button type="button"
									class="btn btn-primary mx-auto d-flex justify-content-center align-items-center">
									<span class="me-2">عرض الكل</span>
									<i class="bi bi-eye"></i>
								</button>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Categories -->
		<section class="categories" v-if="homeData.data.categories">
			<div class="container py-5">
				<!-- Section Title -->
				<div class="text-container">
					<h2 class="section-title display-3 text-center">أقسام الموقع</h2>
				</div>

				<!-- Categories Container -->
				<div class="categories-content pt-5">
					<div class="row g-4">
						<div class="col-12 col-sm-6 col-md-4 col-xl-3" v-for="(category, index) in homeData.data.categories"
							:key="category.id" data-aos="fade-right" :data-aos-delay="index * 50">
							<div class="category-box card d-flex flex-column overflow-hidden">
								<img :src="category.image" :alt="category.title" class="img-fluid w-100 h-100" />
								<div
									class="category-details card-img-overlay px-5 d-flex flex-column justify-content-center align-items-center">
									<h5 class="title card-title text-center text-limit w-100" style="--lines: 1">
										{{ category.title }}
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Featured Blogs -->
		<section class="blogs featured-blogs" v-if="homeData.data.featured">
			<div class="container py-5">
				<!-- Section Title -->
				<div class="text-container">
					<h2 class="section-title display-3 text-center">المقالات المميزة</h2>
				</div>

				<!-- Blogs Swiper -->
				<div class="blog-content pt-5">
					<swiper dir="rtl" :grabCursor="true" :autoplay="{
						delay: 2500,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}" :modules="modules" :slides-per-view="1" :space-between="10" :loop="true" :navigation="navigation" :breakpoints="{
	'@0.00': {
		slidesPerView: 1,
		spaceBetween: 10,
	},
	'@0.75': {
		slidesPerView: 2,
		spaceBetween: 20,
	},
	'@1.25': {
		slidesPerView: 3,
		spaceBetween: 30,
	},
	'@1.50': {
		slidesPerView: 4,
		spaceBetween: 40,
	},
}" class="featured-blogs-slider">
						<swiper-slide class="item" v-for="slider in homeData.data.featured" :key="slider.id">
							<div class="blog-box card d-flex flex-column overflow-hidden">
								<div class="blog-image h-100 overflow-hidden">
									<img :src="slider.image" class="card-img-top img-fluid w-100 h-100"
										:alt="slider.title" />
								</div>
								<div class="blog-details card-body py-3 px-2">
									<h5 class="title card-title text-limit" style="--lines: 2">
										{{ slider.title }}
									</h5>

									<!-- View Details -->
									<div class="mt-3 text-center">
										<router-link class="btn btn-primary" :to="`/blog/${slider.id}`">
											<span class="me-2 ">التفاصيل</span>
											<i class="bi bi-arrow-left"></i>
										</router-link>
									</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</section>

		<!-- Contact Us -->
		<section class="contact">
			<div class="container py-5">
				<!-- Section Title -->
				<div class="text-container">
					<h2 class="section-title display-3 text-center">تواصل معنا الآن</h2>
				</div>

				<!-- Form Container -->
				<div class="form-content pt-5">
					<form class="" action="" @submit.prevent="handleContactForm">
						<div class="row g-4">
							<!-- Full Name -->
							<div class="col-12 form-group" data-aos="fade-down" data-aos-duration="1000">
								<input name="name" type="text" class="form-control" placeholder="الاسم كاملاً"
									aria-label="الاسم كاملاً" v-model="contactFormData.name" v-on:keyup="nameMonitor" />
								<div class="invalid-feedback d-block" v-if="contactErrors.name">
									{{ contactErrors.name }}
								</div>
							</div>

							<!-- Email -->
							<div class="col-12 form-group"  data-aos="fade-down" data-aos-duration="1500">
								<input name="email" type="email" class="form-control" placeholder="البريد الإلكتروني"
									aria-label="البريد الإلكتروني" v-model="contactFormData.email"
									v-on:keyup="emailMonitor" />
								<div class="invalid-feedback d-block" v-if="contactErrors.email">
									{{ contactErrors.email }}
								</div>
							</div>

							<!-- Phone Number -->
							<div class="col-12 form-group" data-aos="fade-down" data-aos-duration="2000">
								<input name="phone" type="number" class="form-control" placeholder="رقم الجوال"
									aria-label="رقم الجوال" v-model="contactFormData.phone" v-on:keyup="phoneMonitor" />
								<div class="invalid-feedback d-block" v-if="contactErrors.phone">
									{{ contactErrors.phone }}
								</div>
							</div>

							<!-- Message -->
							<div class="col-12 form-group" data-aos="fade-down" data-aos-duration="2500">
								<textarea name="message" rows="6" class="form-control" placeholder="اكتب رسالتك هنا ..."
									aria-label="اكتب رسالتك هنا ..." v-model="contactFormData.message"
									v-on:keyup="messageMonitor"></textarea>
								<div class="invalid-feedback d-block" v-if="contactErrors.message">
									{{ contactErrors.message }}
								</div>
							</div>

							<!-- Submit Button -->
							<div class="col-12 d-flex justify-content-center align-items-center" data-aos="fade-down" data-aos-duration="3000">
								<button class="btn " type="submit">
									<i class="bi bi-send"></i>
									<span class="ms-2">إرسال الرسالة</span>
								</button>
							</div>

							<!-- Sent Successfully Feedback Message -->
							<div class="valid-feedback" v-if="sent">
								تم إرسال الرسالة بنجاح
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		return {
			modules: [EffectCoverflow, Pagination, Autoplay],
		};
	},

	data() {
		return {
			formDataFields: {
				text: null,
			},
			sent: false,
			contactFormData: {
				name: null,
				phone: null,
				email: null,
				message: null,
			},
			contactErrors: {
				name: null,
				email: null,
				phone: null,
				message: null,
			},
		};
	},

	name: 'HomeView',

	methods: {
		...mapActions(['filterFormData']),
		onSubmitForm(e) {
			e.preventDefault();

			this.filterFormData(this.formDataFields).then(() => {
				if (this.$store.state.searchParams) {
					e.target.reset();
					this.formDataFields.text = null;
				}
			});
		},

		...mapActions(['storeFormData']),
		handleContactForm(e) {
			this.sending = true;
			this.sent = null;
			this.storeFormData(this.contactFormData).then(() => {
				if (!this.$store.state.contactErrorsApi) {
					e.target.reset();
					this.sent = true;
					this.contactFormData.name = null;
					this.contactFormData.email = null;
					this.contactFormData.phone = null;
					this.contactFormData.message = null;
					this.contactErrors.name = null;
					this.contactErrors.email = null;
					this.contactErrors.phone = null;
					this.contactErrors.message = null;
				} else {
					this.sent = null;
					let errors = this.$store.state.contactErrorsApi.data.data;
					this.contactErrors.name = errors.name[0];
					this.contactErrors.email = errors.email[0];
					this.contactErrors.phone = errors.phone[0];
					this.contactErrors.message = errors.message[0];
				}
			});
		},
	},

	computed: {
		...mapState(['homeData']),
	},
	created() {
		this.$store.dispatch('getHomeData');
	},
};
</script>
