<template>
	<!-- <meta charset="UTF-8" />
	<meta name="description" content="description here" />
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<meta name="keywords" content="" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0" /> -->
	<link rel='icon' :href="settingsData.logo">

	<title>
		{{ settingsData.website_title }}
	</title>

	<!-- Loading Component -->
	<LoadingComponent />

	<!-- Navbar Component -->
	<NavbarComponent :headerData="settingsData" />

	<!-- Actual Page Content -->
	<router-view />

	<!-- Footer Component -->
	<FooterComponent :footerData="settingsData" />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import NavbarComponent from '@/components/NavbarComponent.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { mapState } from 'vuex';

export default {
	components: {
		NavbarComponent,
		FooterComponent,
		LoadingComponent,
	},

	data() {
		return {
			isLoaded: false,
			headerData: [],
			footerData: [],
			isHome: null,
			showToTopBtn: null,
			headerFixedVal: null,
			faviconLink: null
		};
	},

	computed: {
		...mapState(['settingsData']),
	},
	mounted() {
		AOS.init({
			duration: 1000,
			once: 'false',
			mirror: 'true',
		});
	},
	created() {
		this.$store.dispatch('getSettingsData');
	},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				if (to.name == 'HomeView') {
					console.log('ddd');
					this.isHome = true;
				} else {
					this.isHome = null;
				}
			},
		},
	},
};
</script>
