<template>
	<footer v-if="footerData" :style="{
		'background-image': `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)) ,url(${require('@/assets/images/backgrounds/footer.jpg')})`
	}">
		<div class=" footer-content">
			<div class="container">
				<div class="row">
					<div class="col-lg-5">
						<div class="footer-box">
							<h3>من نحن</h3>
							<div class="footer-description">
								<p v-html="footerData.footer_description"></p>
							</div>
							<div class="social-media">
								<ul>
									<li>
										<a :href="footerData.facebook" target="_blank"><i class="bi bi-facebook"> </i></a>
									</li>
									<li>
										<a :href="footerData.twitter" target="_blank"><i class="bi bi-twitter"> </i></a>
									</li>
									<li>
										<a :href="footerData.instagram" target="_blank"><i class="bi bi-instagram"> </i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="footer-box">
							<h3>خريطة الموقع</h3>
							<div class="links">
								<ul>
									<li>
										<router-link :to="{ name: 'Home' }">الرئيسية</router-link>
									</li>
									<li>
										<router-link :to="{ name: 'About' }">من نحن</router-link>
									</li>
									<li>
										<router-link :to="{ name: 'Blogs' }">المقالات</router-link>
									</li>
									<li>
										<router-link :to="{ name: 'Contact' }">تواصل معنا</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="footer-box">
							<h3>معلومات التواصل</h3>
							<div class="contact">
								<ul>
									<li>
										<i class="bi bi-envelope"> </i>
										<a class="px-2" :href="`mailto:${footerData.email}`">{{
											footerData.email
										}}</a>
									</li>
									<li>
										<i class="bi bi-telephone"></i>
										<a class="px-2" :href="`tel:${footerData.phone}`">
											{{ footerData.phone }}
										</a>
									</li>
									<li>
										<i class="bi bi-pin-map"></i><span class="px-2"> {{ footerData.address }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright text-white">
			جميع الحقوق محفوظة معلومةBawan@2030@ ©2023،
			&nbsp;
			<a target="_blank" href="https://marwan.tech/ar/service-request">
				تصميم وبرمجة بواسطة مروان جروب لتقنية المعلومات
			</a>
		</div>
	</footer>
</template>

<script>
export default {
	props: {
		footerData: Array,
	},
};
</script>
